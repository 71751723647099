import React from 'react';
import { Outlet, Link } from 'react-router-dom';
import { MdForward } from 'react-icons/md'; // Import a more dynamic forward icon

function Okrs() {
  return (
    <div className="py-6">
      <h1 className="font-bold text-xl text-gray-800 mb-4">Bảng tổng hợp OKR</h1>
      <nav className="mb-4 flex items-center">
        <Link to="summary" className="text-red-900 hover:text-red-600 transition duration-300 flex items-center">
          Giới thiệu <MdForward className="ml-2" />
        </Link>
        <Link to="scoreboard" className="text-red-900 hover:text-red-600 transition duration-300 flex items-center">
          Bảng tổng sắp<MdForward className="ml-2" />
        </Link>
        <Link to="company" className="text-red-900 hover:text-red-600 transition duration-300 flex items-center">
          &nbsp;Công ty<MdForward className="ml-2" />
        </Link>
        <Link to="departments" className="text-red-900 hover:text-red-600 transition duration-300 flex items-center">
          Phòng ban<MdForward className="ml-2" />
        </Link>
        <Link to="departmentsarchives" className="text-red-900 hover:text-red-600 transition duration-300 flex items-center">
          Kho lưu trữ<MdForward className="ml-2" />
        </Link>
      </nav>
      <Outlet /> {/* This renders the current route's component */}
    </div>
  );
}

export default Okrs;
