import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { fetchChartData, fetchMonthlyTargetsData } from './services/SalesDataService';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  } from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const SalesComparisonChart = () => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: []
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const options = {
    scales: {
      y: {
        beginAtZero: true
      }
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'tình hình thực hiện kế hoạch năm'
      },
    }
  };

  useEffect(() => {
    const token = sessionStorage.getItem('authToken');
    const currentYear = new Date().getFullYear(); // Calculate current year

    const loadData = async () => {
      try {
        const salesData = await fetchChartData(token);
        const allTargetsData = await fetchMonthlyTargetsData(token);
        const targetsDataCurrentYear = allTargetsData.filter(t => t.year === currentYear);

        if (!salesData || targetsDataCurrentYear.length === 0) {
          throw new Error('No data available for the specified year');
        }

        // Transform the targets data into a format suitable for the chart
        const monthlyTargets = targetsDataCurrentYear[0];
        const targetValues = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'].map(month => monthlyTargets[month]);

        setChartData({
          labels: salesData.labels,
          datasets: [
            ...salesData.datasets,
            {
              type: 'line',
              label: 'Kế hoạch',
              data: targetValues,
              backgroundColor: 'rgba(255, 206, 86, 0.2)',
              borderColor: 'rgba(255, 206, 86, 1)',
              borderWidth: 3,
              fill: false,
            }
          ]
        });
        setIsLoading(false);
      } catch (err) {
        console.error("Failed to load chart data:", err);
        setError(`Error: ${err.message}`);
        setIsLoading(false);
      }
    };

    loadData();
  }, []);

  if (isLoading) return <p>Loading sales data...</p>;
  if (error) return <p>{error}</p>;
  if (!chartData || chartData.datasets.length === 0) return <p>No sales data found.</p>;

  return (
    <div className="p-4 bg-white rounded shadow">
      <p>Biểu đồ bán hàng qua các tháng</p>  
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default SalesComparisonChart;
