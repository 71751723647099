import React, { useState } from 'react';
import axios from 'axios';
import { useCurrentUser } from './UserContext';

function UpdateTicketStatus({ ticketId, currentStatus, ticket, onUpdate }) {
    const currentUser = useCurrentUser();
    const [selectedStatus, setSelectedStatus] = useState(currentStatus);
    const [comment, setComment] = useState(''); // New state for comment
    const [error, setError] = useState('');

    const handleChangeStatus = (e) => {
        setSelectedStatus(e.target.value);
    };

    const handleCommentChange = (e) => {
        setComment(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        if (!currentUser || !currentUser.department || currentUser.department.code !== 'SV') {
            setError('Unauthorized: Only users from department Customer Service can update tickets.');
            return;
        }

        const userToken = localStorage.getItem('userToken');
        const API_URL = `/api/tickets/${ticketId}/update_status`;

        try {
            const response = await axios.post(API_URL, {
                status: selectedStatus,
                comment: comment  // Send comment along with status
            }, {
                headers: {
                    'Authorization': `Token ${userToken}`,
                    'Content-Type': 'application/json',
                }
            });

            if (response.status === 200) {
                onUpdate();
                setError('');
            } else {
                setError('Failed to update ticket status.');
            }
        } catch (error) {
            setError('Error updating ticket status.');
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            {error && <div style={{ color: 'red' }}>{error}</div>}
            <select value={selectedStatus} onChange={handleChangeStatus} className="mr-2 p-1 rounded border-gray-300 shadow-sm">
                <option value="open">Open</option>
                <option value="in_progress">In Progress</option>
                <option value="resolved">Resolved</option>
                <option value="closed">Closed</option>
            </select>
            <textarea 
                placeholder="Add a comment..."
                value={comment}
                onChange={handleCommentChange}
                className="mt-2 p-1 w-full rounded border-gray-300"
            />
            <button type="submit" className="py-1 px-2 mt-2 bg-blue-500 text-white rounded hover:bg-blue-700">
                Update
            </button>
        </form>
    );
}

export default UpdateTicketStatus;
