// OKRDepartments.js
import React, { useState, useEffect } from 'react';
import { BiTimeFive } from "react-icons/bi";
import { RiCommunityLine } from 'react-icons/ri';
import { PiTreeStructureLight, PiTarget } from "react-icons/pi";
import { FaWeightHanging } from "react-icons/fa";
import { GiProgression } from "react-icons/gi";
import { OKRService } from './services/OKRService'; 
import './css/OKRDepartments.css';


const getCurrentQuarterAndYear = () => {
  const date = new Date();
  const quarter = Math.floor((date.getMonth() + 3) / 3);
  const year = date.getFullYear();
  return `Q${quarter}-${year}`;
};

const isDateInCurrentQuarter = (dateString) => {
  const date = new Date(dateString);
  const currentDate = new Date();  
  const quarter = Math.floor((date.getMonth() + 3) / 3);
  const currentQuarter = Math.floor((currentDate.getMonth() + 3) / 3);
  return date.getFullYear() === currentDate.getFullYear() && quarter === currentQuarter;
};
function formatVietnameseDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString('vi-VN');
  }
  
const OKRDepartments = () => {
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [departmentObjectives, setDepartmentObjectives] = useState([]);
  const [employeeObjectives, setEmployeeObjectives] = useState([]);
  const [showUserCompletion, setShowUserCompletion] = useState(false);
  const [userCompletions, setUserCompletions] = useState([]);

  const currentQuarterAndYear = getCurrentQuarterAndYear(); // Get current quarter and year

  useEffect(() => {
      OKRService.fetchDepartments()
          .then(setDepartments)
          .catch(error => console.error("Fetching departments failed:", error));
  }, []);

  useEffect(() => {
    if (selectedDepartment) {
        // Fetching department objectives
        OKRService.fetchDepartmentObjectives(selectedDepartment)
            .then(data => {
                const filteredObjectives = data.filter(obj => 
                    isDateInCurrentQuarter(obj.start_date) && isDateInCurrentQuarter(obj.end_date)
                );
                setDepartmentObjectives(filteredObjectives);
            })
            .catch(error => console.error(`Fetching department objectives failed:`, error));

        // Fetching employee objectives
        OKRService.fetchDepartmentEmployeesObjectives(selectedDepartment)
            .then(data => {
                const filteredEmployeeObjectives = data.filter(obj => 
                    isDateInCurrentQuarter(obj.start_date) && isDateInCurrentQuarter(obj.end_date)
                );
                setEmployeeObjectives(filteredEmployeeObjectives);

                // Group objectives by user for individual averages
                const userGroupedObjectives = filteredEmployeeObjectives.reduce((acc, obj) => {
                    const key = obj.user_name;
                    if (!acc[key]) {
                        acc[key] = [];
                    }
                    acc[key].push(obj);
                    return acc;
                }, {});
                setEmployeeObjectives(userGroupedObjectives);
                // Calculate weighted average completion for each user
                const userCompletions = Object.keys(userGroupedObjectives).map(userName => {
                    const objectives = userGroupedObjectives[userName];
                    const totalWeight = objectives.reduce((sum, obj) => sum + obj.weight, 0);
                    const weightedCompletion = objectives.reduce((sum, obj) => sum + (obj.completion_percentage * obj.weight), 0);
                    const averageScore = (totalWeight > 0) ? (weightedCompletion / totalWeight) : 0;

                    return {
                        userName,
                        completionPercentage: averageScore.toFixed(2)
                    };
                });

                // Calculate simple average of user completions for departmentAverageScore
                const departmentAverageScore = userCompletions.length > 0 
                    ? (userCompletions.reduce((sum, user) => sum + parseFloat(user.completionPercentage), 0) / userCompletions.length).toFixed(2)
                    : 'N/A';

                setUserCompletions([{ departmentAverageScore, userCompletions }]);
            })
            .catch(error => {
                console.error(`Fetching employee objectives for department ${selectedDepartment} failed:`, error);
            });
    }
}, [selectedDepartment]);

  const getTextAfterDash = (text) => {
    return text.includes('--') ? text.split('--')[1].trim() : text;
  };  
  
const renderObjectiveDetails = (objective) => {
    if (!objective.keyresults) {
        console.error('Key results missing for objective:', objective);
        return <p>No key results available for this objective.</p>; // Provide a fallback UI
    }

    const numberOfWeeks = 13;
    const currentDate = new Date();

    return (
        <div className='mt-2'>
        <div className="font-bold text-base items-center">
            <span className='text-base font-bold'>Mục tiêu: {getTextAfterDash(objective.title)} </span>
            <PiTarget className="inline-block mr-1"/>
            <span className='text-sm font-thin'>{objective.description}&nbsp;</span>
            <FaWeightHanging className="inline-block"/>
            x<span className='text-sm font-bold'>{objective.weight} &nbsp;</span>
            <GiProgression className="inline-block"/> <span className="text-md text-red-900">{objective.completion_percentage?.toFixed(2) || 'N/A'}%</span>
        </div>

        <table className="table-fixed w-full border-collapse border border-gray-400">
            <thead>
                <tr>
                    <th className="w-1/5 border border-gray-400 text-left text-sm">Các kết quả chính</th>
                    <th className='font-base text-sm'>S/W/T</th>
                    {Array.from({ length: numberOfWeeks }).map((_, index) => (
                        <th className='text-xs' key={index}>W{index + 1}</th> // Add headers for each week
                    ))}
                    <th className='font-base text-sm'>%KR</th>
                </tr>
            </thead>
            <tbody>
                    {objective.keyresults.map((kr, index) => (
                        <tr key={index}>
                            <td className="w-1/10 border border-gray-400 text-xs ">{getTextAfterDash(kr.description)}</td>
                            <td className="border border-gray-400">{kr.total_score_this_quarter.toFixed(2)}, {kr.weight}, {kr.target_score}</td>
                            {kr.historical_scores.map((score, scoreIndex) => {
                                const scoreDate = new Date(score.evaluation_date);
                                let cellClass = "border border-gray-400 ";
                                if (scoreDate > currentDate) {
                                    cellClass += "bg-gray-500 text-gray-500"; // Style for future dates
                                } else if (score.completed) {
                                    cellClass += "bg-green-900 text-green-900"; // Style if completed
                                } else if (score.noscore) {
                                    cellClass += "bg-gray-100 text-gray-900"; // Style if no score is to be considered
                                } else if (score.score <= kr.target_score / 13 * 0.3) {
                                    cellClass += "bg-red-500"; // Low score
                                } else if (score.score > kr.target_score / 13 * 0.3 && score.score <= kr.target_score / 13 * 0.6) {
                                    cellClass += "bg-yellow-500"; // Middle range score
                                } else if (score.score > kr.target_score / 13 * 0.6) {
                                    cellClass += "bg-green-500"; // High score
                                }
                                return (
                                    <td key={scoreIndex} className={cellClass} title={`đánh giá@${formatVietnameseDate(score.evaluation_date)}: ${score.notes || ''} `}>
                                        {score.score}
                                    </td>
                                );
                            })}
                            <td className="py-4 border border-gray-400"> {((kr.total_score_this_quarter / kr.target_score) * 100).toFixed(2) || 'N/A'}%</td>
                        </tr>
                    ))}
                </tbody>
        </table>
        </div>
    );
};

    return (
        <div>
        <div className="flex flex-col items-center py-4 w-full">
        <div className="mb-2 flex flex-col items-center">
            <span className="text-lg font-semibold text-gray-700">
            Hãy click để chọn phòng ban cần xem
            </span>
            <RiCommunityLine className="text-5xl text-red-500 mt-2 mb-2" />
        </div>
        <div className="w-full px-4">
            <div className="flex justify-center space-x-2">
            {departments.map((dept) => (
                <button
                key={dept.id}
                onClick={() => setSelectedDepartment(dept.id)}
                className="flex items-center justify-center border border-red-900 bg-white hover:bg-red-300 text-gray-900 font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-colors duration-300"
                >
                {dept.name}
                </button>
            ))}
            </div>
        </div>
        </div>
        <h3 className='py-8 text-red-900 text-lg font-semibold'>BẢNG TỔNG HỢP OKR CÁ NHÂN VÀ BỘ PHẬN</h3>
    
        {selectedDepartment && (
            <div>
            <div className="flex justify-center items-center">
            <div className='info-box border-2 border-dashed p-8 border-red-900 inline-block text-center'>
                <div className='flex items-center'>
                    <PiTreeStructureLight className="mr-2"/>
                    <p className='text-xl font-bold'>Bộ phận: {departments.find(d => d.id === selectedDepartment)?.name.toUpperCase()}</p>
                </div>
                <div className='flex items-center'>
                    <BiTimeFive className="mr-2"/>
                    <p className='text-sm font-bold pb-2'>Kỳ tính điểm:&nbsp;{currentQuarterAndYear}</p>
                </div>
            </div>   
            </div>
            <div className="flex justify-between items-center w-full pt-8">
        <div className="flex items-center justify-center text-sm font-semibold">
        <h3>&nbsp;</h3>
        </div>
        <div className="flex space-x-4"> 
        <div className="flex items-top justify-center text-sm font-semibold">
        Điểm đánh giá:
        </div>    
        <div className="w-16 h-16 bg-red-500 flex items-center justify-center text-white text-sm font-semibold">
        Không đạt
        </div>
        <div className="w-16 h-16 bg-yellow-500 flex items-center justify-center text-white text-sm font-semibold">
        Đạt 30-60%
        </div>
        <div className="w-16 h-16 bg-green-500 flex items-center justify-center text-white text-sm font-semibold">
        Hơn 60%
        </div>
        <div className="w-16 h-16 bg-gray-500 flex items-center justify-center text-white text-sm font-semibold">
        Chưa đánh giá
        </div>
        <div className="w-16 border border-1 h-16 bg-white flex items-center justify-center text-sm font-semibold">
        Không chấm điểm*
        </div>
        <div className="w-16 h-16 bg-green-900 flex items-center justify-center text-white text-sm font-semibold">
        Hoàn thành**
        </div>
        </div>
        </div>
        <div className="w-full pb-4">
            <div className="flex flex-col items-end text-sm font-semibold">
                <h3>&nbsp;</h3>
                <div className="">
                    Ký hiệu (S/W/T): <span className="text-sm font-thin my-1">
                        S (Score): điểm hiện tại
                    </span>
                </div>
                <div className="flex flex-col items-end">
                    <div className="flex items-center justify-center text-sm font-thin my-1">
                        W (Weight): trọng số
                    </div>
                    <div className="flex items-center justify-center text-sm font-thin my-1">
                        T (Target score): điểm đích
                    </div>
                </div>
            </div>
        </div>    
            <p className='pt-8 pb-4 text-red-900 text-xl font-semibold'>OKR ĐỘI NGŨ</p> 
            </div>
        )}

        {departmentObjectives.map((obj, index) => (
            <div key={index}>
                {renderObjectiveDetails(obj)}
            </div>
        ))}
            <p className='pt-4 font-thin text-sm'>Bật tắt bảng điểm&nbsp;</p>
            <label className="switch">
            <input type="checkbox" checked={showUserCompletion} onChange={() => setShowUserCompletion(!showUserCompletion)} />
            <span className="slider round"></span>
        </label>
        {showUserCompletion && (
            <div className="mt-4">
                <h4 className='text-lg pt-4 font-bold'>Điểm trung bình Bộ phận: {userCompletions.length > 0 ? userCompletions[0].departmentAverageScore : 'N/A'}%</h4>
                <h4 className='text-sm pt-8 pb-4 font-bold text-red-900'>Bảng tổng sắp điểm OKR cá nhân:</h4>
                {userCompletions.length > 0 && userCompletions[0].userCompletions && userCompletions[0].userCompletions.map((user, index) => {
                    let bgColor;
                    if (user.completionPercentage < 30) {
                        bgColor = 'bg-red-600';
                    } else if (user.completionPercentage >= 30 && user.completionPercentage <= 60) {
                        bgColor = 'bg-yellow-600';
                    } else {
                        bgColor = 'bg-green-600';
                    }

                    return (
                        <div key={index} className="mt-2 flex items-center">
                            <span className='text-sm font-semibold w-1/4'>{user.userName}: {user.completionPercentage}%</span>
                            <div className="w-3/4 bg-gray-200 rounded-full h-4 dark:bg-gray-700 ml-2">
                                <div className={`${bgColor} h-4 rounded-full`} style={{ width: `${user.completionPercentage}%` }}></div>
                            </div>
                        </div>
                    );
                })}
            </div>
        )}
        <h4 className='pt-16 pb-4 text-red-900 text-xl font-semibold'>OKR CÁ NHÂN</h4>

    {selectedDepartment && (
        <>  


    {Object.keys(employeeObjectives).map((userName, index) => (
    <div key={userName} className="employee-objectives">
        <h4 className='font-bold text-base text-red-500 pt-8'>{index + 1} - Chủ nhân OKR: <span className='uppercase'> {userName}</span> </h4>
        {employeeObjectives[userName].map(obj => (
            <div key={obj.id} className="objective-detail">
                {renderObjectiveDetails(obj)}
            </div>
        ))}
    </div>
    ))}
                </>
            )}
        <h3 className='pt-4 text-sm font-bold'>Chú thích:</h3>    
        <p className='italic text-xs'>*Không chấm điểm: "Không chấm điểm" khác với không đạt ở chỗ là khi OKR của bạn là viết 1 bài trong kỳ tính điểm nhưng do bạn còn đang nháp, tìm chủ đề v.v thì nỗ lực của bạn vẫn được ghi nhận trong notes (khi bạn rê chuột vô ô chấm điểm) nhưng điểm bạn chưa có nên để trắng. Khi bạn viết xong bài thì điểm của bạn sẽ là 100% và khi đó bạn sẽ có điểm "xanh" hoặc "vàng", còn nếu không viết luôn cuối kỳ bạn bị "đỏ".</p>    
        <p className='italic text-xs'>**Hoàn thành: KR đã hoàn thành trước hạn, vẫn tiếp tục theo dõi nhưng không chấm điểm thêm.</p>
    </div>            
    );
};

export default OKRDepartments;