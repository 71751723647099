import React from 'react';
import { Bar as BarGraph } from 'react-chartjs-2';
import 'chart.js/auto';

const DepartmentGraph = ({ departments }) => {
    if (!departments || departments.length === 0) {
        return <p>Không có dữ liệu.</p>;
    }

    const chartData = {
        labels: departments.map(item => item.departmentName),
        datasets: [{
            label: '% hoàn thành',
            data: departments.map(item => parseFloat(item.averageScore)),
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 1
        }]
    };

    const chartOptions = {
        scales: {
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Điểm trung bình phòng ban (%)'
                }
            },
            x: {
                title: {
                    display: true,
                    text: 'Phòng ban Digi'
                }
            }
        },
        responsive: true,
        maintainAspectRatio: false
    };

    return (
        <div style={{ height: '400px', width: '100%' }}>
            <BarGraph data={chartData} options={chartOptions} />
        </div>
    );
};

export default DepartmentGraph;
