import React from 'react';
import dashboardImage from './images/dashboard.svg';

const Introduction = () => {

    return (
        <div>
            <h2 className='font-bold text-red-400 my-2'>Giới thiệu về Dashboard</h2>
            <div className="p-5 bg-gray-100 rounded-lg shadow">
      <h1 className="text-xl font-semibold text-gray-800 mb-4">Dashboard là gì?</h1>
      <p className="mb-2">
        Trong một công ty thương mại, dashboard đóng một vai trò cực kỳ quan trọng trong việc hỗ trợ quản lý và điều hành doanh nghiệp. Nó giúp cung cấp cái nhìn tổng quan và sâu sắc về các hoạt động kinh doanh, từ đó hỗ trợ ra quyết định nhanh chóng và chính xác.
      </p>
      <p className="mb-2">
        Các ứng dụng chính của dashboard trong công ty thương mại bao gồm theo dõi doanh số và doanh thu, quản lý tồn kho, giám sát hiệu suất các chiến dịch tiếp thị, phân tích khách hàng và phân khúc thị trường, đánh giá hiệu quả nhân sự, và báo cáo tài chính.
      </p>
      <img src={dashboardImage} alt="Descriptive Alt Text" className="w-full h-auto" />
      <p className="mb-2">
        Các dashboard thường được thiết kế để dễ dàng tùy chỉnh và cập nhật, đảm bảo phù hợp với nhu cầu đặc thù của từng công ty và ngành hàng. Việc áp dụng công nghệ dashboard hiệu quả không chỉ giúp cải thiện quản lý doanh nghiệp mà còn tăng cường khả năng cạnh tranh trên thị trường.
      </p>
      <p className="mb-2"><strong>
      Theo dõi Doanh số và Doanh thu:</strong> Dashboard có thể hiển thị thông tin về doanh số bán hàng theo ngày, tuần, tháng hoặc năm, giúp lãnh đạo nắm bắt được xu hướng doanh thu và đánh giá hiệu quả của các chiến lược kinh doanh.
      </p>
      <p className="mb-2">
      <strong>Quản lý Tồn kho:</strong> Một dashboard hiệu quả cung cấp thông tin cập nhật về mức tồn kho, sản phẩm bán chạy, và cần được bổ sung. Điều này giúp công ty tránh tình trạng thiếu hàng hoặc dư thừa hàng tồn kho, từ đó tối ưu hoá chi phí.
</p>
<p className="mb-2">
<strong>Giám sát Hiệu suất Các Chiến dịch Tiếp thị:</strong> Dashboard có thể theo dõi hiệu quả của các chiến dịch tiếp thị qua các chỉ số như lượng truy cập web, số lượt chuyển đổi, ROI (lợi nhuận trên chi phí đầu tư) và các chỉ số khác liên quan đến chiến dịch.
</p>
<p className="mb-2"><strong>
Phân tích Khách hàng và Phân khúc Thị trường:</strong> Các công cụ dashboard cho phép phân tích hành vi mua hàng của khách hàng, đánh giá các phân khúc thị trường, và nhận diện các cơ hội kinh doanh mới. Điều này giúp công ty điều chỉnh sản phẩm và dịch vụ cho phù hợp với nhu cầu của khách hàng.
</p>
<p className="mb-2">
<strong>    
Đánh giá Hiệu quả Nhân sự: </strong>Dashboard cũng có thể được sử dụng để giám sát hiệu quả công việc của nhân viên thông qua các chỉ số như năng suất lao động, mức độ hài lòng trong công việc, và mức độ tham gia các khóa đào tạo.
</p>
<p className="mb-2">
<strong>    
Tài chính và Báo cáo:</strong> Một dashboard tài chính sẽ tổng hợp các chỉ số quan trọng như lưu chuyển tiền mặt, lợi nhuận và lỗ, và các chỉ số tài chính khác, giúp quản lý nắm bắt được tình hình tài chính của công ty một cách nhanh chóng và chính xác.
</p>
<p className="mb-2">
Các dashboard trong công ty thương mại thường được thiết kế để dễ dàng tùy chỉnh và cập nhật, đảm bảo phù hợp với nhu cầu đặc thù của từng công ty và ngành hàng. Việc áp dụng công nghệ dashboard hiệu quả không chỉ giúp cải thiện quản lý doanh nghiệp mà còn tăng cường khả năng cạnh tranh trên thị trường.  
</p>
    </div>
        </div>
    );
};

export default Introduction;
