import React, { useState, useEffect } from 'react';
import { OKRService } from './services/OKRService'; 

const OKRCompany = () => {
    const [objectives, setObjectives] = useState([]);

    useEffect(() => {
        OKRService.fetchCompanyObjectives()
            .then(setObjectives)
            .catch(error => console.error("Fetching company objectives failed:", error));
    }, []);

    return (
        <div>
            <h2 className='font-bold text-red-400 my-2'>Mục tiêu OKR Công ty:</h2>
            {objectives.map(obj => (
                <div key={obj.id}>
                    <h3>{obj.title}</h3>
                    <p>Description: {obj.description}</p>
                    <p>Đã thực hiện: {obj.completion_percentage}%</p>
                    <h4 className='font-semibold text-red-300 my-1'>Các kết quả chính:</h4>
                    <ul>
                        {obj.keyresults.map(kr => (
                            <li key={kr.id}>
                                <h4 className='font-semibold text-sm text-red-200 my-1'>{kr.description}</h4>
                                <ul>
                                    <li>Target Score: {kr.target_score}</li>
                                    <li>Total Score This Quarter: {kr.total_score_this_quarter}</li>
                                    <li>Weight: {kr.weight}</li>
                                </ul>
                            </li>
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    );
};

export default OKRCompany;
