import axios from 'axios';

// Function to fetch sales data with the token included in the request headers
export const fetchSalesData = async (token) => {
  try {
    const response = await axios.get(`/api/dashboard/sales-data/`, {
      headers: {
        'Authorization': `Token ${token}`, // Set the authorization header
        'Content-Type': 'application/json'  // Ensure content type is set to application/json
      }
    });
    return response.data; // Return the data received from the server
  } catch (error) {
    console.error('Error fetching sales data:', error);
    return null; // Consider appropriate error handling
  }
};

// Function to fetch the newly formatted chart data
export const fetchChartData = async (token) => {
    try {
      const response = await axios.get(`/api/dashboard/sales-chart-data/`, {
        headers: {
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json'
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching chart data:', error);
      return null;
    }
  };

  export const fetchBranchSalesData = async (token) => {
    try {
      const response = await axios.get(`/api/dashboard/sales-by-branch/`, {
        headers: {
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json'
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching branch sales data:', error);
      // Log detailed error information
      console.log(error.response);  // Log the full error response object
      return null;
    }
  };
  
  export const fetchEmployeeSalesData = async (token) => {
    try {
      const response = await axios.get('/api/dashboard/employee-sales/', {
        headers: {
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json'
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching employee sales data:', error);
      return null;
    }
  };
  
  export const fetchMonthlyTargetsData = async (token) => {
    try {
      const response = await axios.get(`/api/dashboard/monthlytargets/`, {
        headers: {
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json'
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching monthly targets data:', error);
      return null;
    }
  };