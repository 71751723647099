import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { FiTarget } from 'react-icons/fi';
import { TiBookmark } from "react-icons/ti";
import { fetchGoalsForPlan, fetchDepartmentAndEmployeePlans, fetchDepartments, fetchStrategiesForGoal, fetchTasksForStrategy } from './services/PlanService';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import backgroundImage from './images/a.jpg';

const Departments = () => {
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [departmentPlans, setDepartmentPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [goals, setGoals] = useState([]);
  const statusColorMapping = {
    NS: 'bg-gray-200', // Not started: light gray
    DO: 'bg-green-500', // Done: green
    OV: 'bg-green-700', // Overhead: dark green
    BE: 'bg-yellow-400', // Behind: yellow
    OF: 'bg-gray-600', // OFF: dark gray
    OD: 'bg-red-500', // Overdue: red
  };
  const statuses = [
    { label: 'Trễ hạn', color: 'bg-red-500' },
    { label: 'Chưa xong', color: 'bg-yellow-400' },
    { label: 'Xong', color: 'bg-green-500' },
    { label: 'Nghỉ', color: 'bg-gray-400' }
  ];
  const renderTaskWeeks = (task) => {
    return [...Array(13).keys()].map(week => {
      const statusKey = `week${week + 1}_status`;
      const descriptionKey = `week${week + 1}_description`;
      return (
        <div key={week} className={`${statusColorMapping[task[statusKey]]} p-2 m-1 flex-1 min-w-[23%] lg:min-w-[13.5%]`}>
          <span className='font-semibold'>W{week + 1}</span>: {task[descriptionKey] || 'N/A'}
        </div>
      );
    });
  };

  useEffect(() => {
    fetchDepartments().then(setDepartments);
  }, []);

  useEffect(() => {
    if (selectedDepartment) {
      const { startDate, endDate } = getCurrentQuarterDates();
      fetchDepartmentAndEmployeePlans(selectedDepartment.id, startDate, endDate)
        .then(plans => {
          /*
          const sortedPlans = plans.sort((a, b) => {
            if (a.category === 'DE' && b.category !== 'DE') return -1;
            if (a.category !== 'DE' && b.category === 'DE') return 1;
            return 0;
          }); */
          const activePlans = plans.filter(plan => plan.is_active);
          setDepartmentPlans(activePlans);
          //  setDepartmentPlans(sortedPlans);
        })
        .catch(console.error);
    }
  }, [selectedDepartment]);

  const handleDepartmentSelect = department => {
    setSelectedDepartment(department);
    setSelectedPlan(null); // Reset selected plan when changing department
    setGoals([]); // Reset goals as well
  };

  const handlePlanSelect = async plan => {
    setSelectedPlan(plan);
    try {
      const fetchedGoals = await fetchGoalsForPlan(plan.id);
      const goalsWithDetails = await Promise.all(fetchedGoals.map(async goal => {
        const strategies = await fetchStrategiesForGoal(goal.id);
        const strategiesWithTasks = await Promise.all(strategies.map(async strategy => {
          const tasks = await fetchTasksForStrategy(strategy.id);
          return { ...strategy, tasks: tasks.map(task => ({ ...task, renderedWeeks: renderTaskWeeks(task) })) };
        }));
        return { ...goal, strategies: strategiesWithTasks };
      }));
      setGoals(goalsWithDetails);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  };

  const getCurrentQuarterDates = () => {
    const now = moment().tz('Asia/Ho_Chi_Minh');
    const firstDate = now.startOf('quarter');
    const lastDate = firstDate.clone().endOf('quarter');
    return { startDate: firstDate.format('YYYY-MM-DD'), endDate: lastDate.format('YYYY-MM-DD') };
  };

  const getTextAfterDash = (text) => {
    return text.includes('--') ? text.split('--')[1].trim() : text;
  };

  return (
    <div>
      <h2 className='font-bold mt-4 mb-2'>Kế hoạch các phòng ban</h2>
      <div>
        {departments.map(department => (
          <button className='text-pink-900 hover:font-bold cursor-pointer' key={department.id} onClick={() => handleDepartmentSelect(department)}>
            ○&nbsp;{department.name}&nbsp;
          </button>
        ))}
      </div>

      {selectedDepartment && (
        <div>
        <h3 className='font-medium text-lg mt-4'>Tiến độ thực hiện kế hoạch</h3>
        <h4 className='text-sm mb-4'>(nhấn vào ô để xem chi tiết từng kế hoạch)</h4>
          <div className="grid grid-cols-4 gap-4">
            {departmentPlans.map(plan => (
              <div key={plan.id} onClick={() => handlePlanSelect(plan)} className="flex flex-col items-center cursor-pointer">
                <div className="w-full p-4 border border-gray-300 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-150">
                  <div className="flex items-center justify-center space-x-4">
                    <div style={{ width: 150, height: 150 }}>
                      <CircularProgressbarWithChildren
                        value={plan.calculated_score / plan.target_score * 100}
                        styles={buildStyles({
                          pathColor: 'green',
                          trailColor: '#d6d6d6',
                          backgroundColor: '#ffffff',
                          pathTransitionDuration: 0.5
                        })}
                        strokeWidth={8}
                        background
                        backgroundPadding={6}
                      >
                        <div style={{ fontSize: 12, marginTop: -5 }}>
                          <strong>{`${(plan.calculated_score/plan.target_score*100).toFixed(0)}%`}</strong>
                        </div>
                      </CircularProgressbarWithChildren>
                    </div>
                    <div>
                      <span className="text-base font-semibold">{plan.owner.name}</span>
                      <div className="text-sm text-gray-600">
                        Điểm: <span className="font-bold">{plan.calculated_score}/{plan.target_score}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="flex justify-center items-center space-x-4 py-8">
        <p>Chú dẫn:</p>
        {statuses.map((status) => (
          <div key={status.label} className="flex flex-col items-center space-y-2">
            <div className={`${status.color} w-20 h-20 flex justify-center items-center rounded`}>
              <span className="text-white font-bold text-lg">{status.label}</span>
            </div>
          </div>
        ))}
      </div>
      {selectedPlan ? (
        <div>
          <h3 className='font-semibold text-lg mt-4 mb-2'>Chi tiết: {getTextAfterDash(selectedPlan.title)} ∆ <span className='uppercase'>{selectedPlan.owner.name}</span></h3>
          {goals.map((goal, index) => (
            <div key={goal.id}>
              <div className="flex items-center pt-2 mt-8">
                <TiBookmark className="mr-2" />
                <span className='font-bold text-lg'>Mục tiêu {index + 1} ({goal.category_name}): </span>&nbsp;{getTextAfterDash(goal.name)} &nbsp;
                <TiBookmark className="mr-2" />
              </div>
              {goal.strategies.map((strategy, strategyIndex) => (
                <div key={strategy.id}>

                  {strategy.tasks.map(task => (
                    <div key={task.id} className="flex flex-wrap">
                      <div className="p-2 m-1 flex-1 min-w-[48%] lg:min-w-[13.5%] bg-sky-200"
                          style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                        <h5>
                          <span className='font-bold text-white'>CHIẾN LƯỢC {strategyIndex + 1}:</span>
                        </h5>
                        {getTextAfterDash(strategy.name)}
                      </div>
                      {task.renderedWeeks}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          ))}
        </div>
      ) : (
        <div className="mt-4 italic">Hãy bấm vào tên bộ phận để xem chi tiết kế hoạch<p>Nếu bạn không thấy gì có nghĩa bộ phận này chưa có kế hoạch</p></div>
      )}
    </div>
  );
};

export default Departments;
