import React, { useState } from 'react';
import TicketService from './services/TicketService';
import { useCurrentUser } from './UserContext'; // Ensure this path is correct

function TicketForm() {
    const currentUser = useCurrentUser();
    const [subject, setSubject] = useState('');
    const [description, setDescription] = useState('');
    const [customer, setCustomer] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        setError('');
        console.log("Current User Data:", currentUser); 
        // Ensure only users from "SV" department can submit
        if (!currentUser || !currentUser.department || currentUser.department.code !== 'SV') {
            setError('Unauthorized: Only users from department "SV" can submit tickets.');
            return;
        }

        if (!subject.trim() || !description.trim() || !customer) {
            setError('Subject, description, and customer ID are required.');
            return;
        }

        const payload = {
            subject: subject.trim(),
            description: description.trim(),
            customer: parseInt(customer, 10) // Assuming customer ID needs to be an integer
        };

        TicketService.createTicket(payload, currentUser.token)
            .then(response => {
                console.log('Ticket created:', response.data);
                setSubject('');
                setDescription('');
                setCustomer('');
                setError(''); // Clear any error messages
            })
            .catch(error => {
                console.error('Error creating ticket:', error.response || error);
                setError('Failed to submit ticket. Please try again.');
            });
    };

    return (
        <form onSubmit={handleSubmit}>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <div>
                <label>Customer ID:</label>
                <input type="number" value={customer} onChange={e => setCustomer(e.target.value)} />
            </div>
            <div>
                <label>Subject:</label>
                <input type="text" value={subject} onChange={e => setSubject(e.target.value)} />
            </div>
            <div>
                <label>Description:</label>
                <textarea value={description} onChange={e => setDescription(e.target.value)}></textarea>
            </div>
            <button type="submit">Submit Ticket</button>
        </form>
    );
}

export default TicketForm;
