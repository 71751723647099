import React, { useState, useEffect, useCallback } from 'react';
import { fetchCompanyPlan, fetchGoalsForPlan, fetchStrategiesForGoal, fetchTasksForStrategy } from './services/PlanService';
import { FiBookOpen, FiInfo, FiUser, FiTarget, FiCompass, FiActivity, } from 'react-icons/fi';

const Company = () => {
  const [plan, setPlan] = useState(null);
  const [goals, setGoals] = useState([]);
  const [selectedGoalId] = useState(null);
  const [strategies, setStrategies] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [selectedStrategyId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
    const getStatusColor = (status) => {
      switch(status) {
        case 'NS': return 'bg-gray-200'; // Not started: light gray
        case 'DO': return 'bg-green-500'; // Done: green
        case 'OV': return 'bg-green-700'; // Overhead: dark green
        case 'BE': return 'bg-yellow-400'; // Behind: yellow
        case 'OF': return 'bg-gray-600'; // OFF: dark gray
        case 'OD': return 'bg-red-500'; // Overdue: red
        default: return 'bg-gray-200';
      }
    };
  const dayjs = require('dayjs');
  const utc = require('dayjs/plugin/utc');
  const timezone = require('dayjs/plugin/timezone');
  const quarterOfYear = require('dayjs/plugin/quarterOfYear');
  
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(quarterOfYear);
  dayjs.tz.setDefault('Asia/Ho_Chi_Minh');

  // ESLint disable for this specific line if dayjs truly has no side effects or dependencies
  const getCurrentQuarterDates = useCallback(() => {
    const firstDate = dayjs().tz('Asia/Ho_Chi_Minh').startOf('quarter');
    const lastDate = dayjs(firstDate).endOf('quarter');
    return {
      startDate: firstDate.format('YYYY-MM-DD'),
      endDate: lastDate.format('YYYY-MM-DD')
    };
  }, []); // Correct usage, no dependencies needed
 
  
  useEffect(() => {
    const fetchCompanyPlanData = async () => {
      const { startDate, endDate } = getCurrentQuarterDates();
      const data = await fetchCompanyPlan(startDate, endDate);
      console.log("Fetched data inside useEffect:", data); 
      setPlan(data); 
    };
  
    fetchCompanyPlanData();
  }, [getCurrentQuarterDates]);
  

  useEffect(() => {
    if (selectedGoalId) {
      fetchGoalsForPlan(selectedGoalId).then(setGoals);
      setStrategies([]);
      setTasks([]);
    }
  }, [selectedGoalId]);

  useEffect(() => {
    if (selectedStrategyId) {
      fetchStrategiesForGoal(selectedStrategyId).then(setStrategies);
      setTasks([]);
    }
  }, [selectedStrategyId]);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setLoading(true);
        const { startDate, endDate } = getCurrentQuarterDates();
        const fetchedPlan = await fetchCompanyPlan(startDate, endDate);
        setPlan(fetchedPlan);
    
        const fetchedGoals = await fetchGoalsForPlan(fetchedPlan.id);
        const goalsWithDetails = await Promise.all(fetchedGoals.map(async (goal) => {
          const strategies = await fetchStrategiesForGoal(goal.id);
          const strategiesWithTasks = await Promise.all(strategies.map(async (strategy) => {
            const tasks = await fetchTasksForStrategy(strategy.id);
            return { ...strategy, tasks };
          }));
          return { ...goal, strategies: strategiesWithTasks };
        }));
    
        setGoals(goalsWithDetails);
      } catch (error) {
        console.error("Failed to fetch data:", error);
        setError('Failed to load data.');
      } finally {
        setLoading(false);
      }
    };    

    fetchInitialData();
  }, [getCurrentQuarterDates]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      {plan ? (
        <div className='py-4'>
          <h2 className="font-bold mt-4">Thông tin chung: {plan.title}</h2>
          <div className="flex">
            <div className="flex-1 flex items-start border p-4 border-r-0 last:border-r">
              <FiBookOpen className="text-2xl mr-4" />
              <div>
                <h3 className="font-semibold">Kế hoạch</h3>
                <p>{plan.title}</p>
              </div>
            </div>
            <div className="flex-1 flex items-start border p-4 border-r-0 last:border-r">
              <FiInfo className="text-2xl mr-4" />
              <div>
                <h3 className="font-semibold">Chi tiết</h3>
                <p>{plan.description}</p>
              </div>
            </div>
            <div className="flex-1 flex items-start border p-4">
              <FiUser className="text-2xl mr-4" />
              <div>
                <h3 className="font-semibold">Trách nhiệm, điểm số</h3>
                <p>{plan?.owner ? `${plan.owner.name}` : 'Owner name not available'}</p>
                <p>Điểm hiện tại: {plan.calculated_score}</p>
                <p>Điểm đích: {plan.target_score}</p>
              </div>
            </div>
          </div>
        </div>
      ) : <p>Loading plan...</p>}

      {goals.map((goal, index) => (
        <div key={goal.id} className="mb-2">
          <div className="flex items-center">
            <FiTarget className="text-2xl mr-2" />
            <h3 className="flex-1">Mục tiêu {index + 1}: {goal.name}</h3>
            <p className="flex-2">{goal.description}</p>
          </div>
          {goal.strategies.map((strategy, strategyIndex) => (
            <div key={strategy.id} className="flex items-center">
              <FiCompass className="text-2xl mr-2" />
              <h4 className="flex-1">Chiến lược {strategyIndex + 1}: {strategy.name}</h4>
              <p className="flex-2">{strategy.description}</p>
            </div>
          ))}
          {goal.strategies.map((strategy) =>
            strategy.tasks.map(task => (
              <div key={task.id} className="flex flex-col items-start">
                <div className="flex items-center w-full">
                  <FiActivity className="text-2xl mr-2" />
                  <p className="flex-1 font-semibold">Tác vụ: {task.name}</p>
                </div>
                <div className="flex flex-wrap justify-start mt-1">
                  {[...Array(13).keys()].map(week => {
                    const status = task[`week${week + 1}_status`];
                    const description = task[`week${week + 1}_description`];
                    return (
                      <div key={week} className={`${getStatusColor(status)} p-2 m-1 flex-1 min-w-[23%]`}>
                        <strong>Tuần {week + 1}</strong>: {description || 'No description'}
                      </div>
                    );
                  })}
                </div>
              </div>
            ))
          )}
        </div>
      ))}
    </div>
  );
}

export default Company;
