import axios from 'axios';

// Convert fetchCompanyPlan to use Axios
export const fetchCompanyPlan = async (startDate, endDate) => {
  try {
    const response = await axios.get(`/api/plan/plans/?category=CO&start_date=${startDate}&end_date=${endDate}`);
    return response.data[0]; // Adjust based on actual data structure
  } catch (error) {
    console.error('Error fetching company plans:', error);
    return null;
  }
};

// Convert fetchGoalsForPlan to use Axios
export const fetchGoalsForPlan = async (planId) => {
  try {
    const response = await axios.get(`/api/plan/goals/?planId=${planId}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching goals for plan ${planId}:`, error);
    return [];
  }
};

// Convert fetchStrategiesForGoal to use Axios
export const fetchStrategiesForGoal = async (goalId) => {
  try {
    const response = await axios.get(`/api/plan/strategies/?goalId=${goalId}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching strategies for goal ${goalId}:`, error);
    return [];
  }
};

// Convert fetchTasksForStrategy to use Axios
export const fetchTasksForStrategy = async (strategyId) => {
  try {
    const response = await axios.get(`/api/plan/tasks/?strategyId=${strategyId}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching tasks for strategy ${strategyId}:`, error);
    return [];
  }
};

// Convert fetchDepartmentPlans to use Axios
export const fetchDepartmentPlans = async (startDate, endDate) => {
  try {
    //const response = await axios.get('/api/plan/plans/?category=DE');
    const response = await axios.get(`/api/plan/plans/?category=DE&start_date=${startDate}&end_date=${endDate}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching department plans:', error);
    return [];
  }
};

// Convert fetchDepartmentAndEmployeePlans to use Axios
export const fetchDepartmentAndEmployeePlans = async (departmentId, startDate, endDate) => {
  try {
    const url = `/api/plan/departments/${departmentId}/plans?start_date=${startDate}&end_date=${endDate}`;
    const response = await axios.get(url);
    console.log("Received data:", response.data); // Debugging: Log the received data
    
    // Filter out the company plan if it's mistakenly included
    const filteredData = response.data.filter(plan => plan.category !== 'CO');
    return filteredData; // Return filtered data
  } catch (error) {
    console.error('Error fetching department and employee plans:', error);
    return []; // Return an empty array or appropriate error handling
  }
};

export const fetchDepartments = async () => {
  try {
    const response = await axios.get('/api/departments/');
    return response.data;
  } catch (error) {
    console.error('Error fetching departments:', error);
    return [];
  }
};