import React, { useState, useEffect } from 'react';
import DepartmentGraph from './DepartmentGraph';
import { OKRService } from './services/OKRService';

const OKRScoreBoard = () => {
    const [departments, setDepartments] = useState([]);
    const [allDepartmentsScore, setAllDepartmentsScore] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        OKRService.fetchDepartments()
            .then(setDepartments)
            .catch(error => {
                console.error("Fetching departments failed:", error);
                setError('Failed to fetch departments');
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if (departments.length > 0) {
            fetchAllDepartmentScores();
        }
    }, [departments]);

    const fetchAllDepartmentScores = () => {
        Promise.all(departments.map(dept => 
            OKRService.fetchDepartmentEmployeesObjectives(dept.id)
            .then(data => {
                const filteredEmployeeObjectives = data.filter(obj => 
                    new Date(obj.start_date) <= new Date() && new Date(obj.end_date) >= new Date()
                );
                if (filteredEmployeeObjectives.length > 0) {
                    const userGroupedObjectives = filteredEmployeeObjectives.reduce((acc, obj) => {
                        const key = obj.user_name;
                        acc[key] = acc[key] || [];
                        acc[key].push(obj);
                        return acc;
                    }, {});
                    const userCompletions = Object.values(userGroupedObjectives).map(objectives => {
                        const totalWeight = objectives.reduce((sum, obj) => sum + obj.weight, 0);
                        const weightedCompletion = objectives.reduce((sum, obj) => sum + (obj.completion_percentage * obj.weight), 0);
                        return (totalWeight > 0) ? (weightedCompletion / totalWeight) : 0;
                    });
                    const departmentAverageScore = userCompletions.length > 0 
                        ? userCompletions.reduce((sum, score) => sum + score, 0) / userCompletions.length
                        : 'N/A';
                    return { departmentName: dept.name, averageScore: `${departmentAverageScore.toFixed(2)}%` }; // Formatting as percentage
                } else {
                    return { departmentName: dept.name, averageScore: 'N/A' };
                }
            })
        ))
        .then(scores => {
            setAllDepartmentsScore(scores);
            setLoading(false);
        })
        .catch(error => {
            console.error("Error fetching department scores:", error);
            setError('Failed to load department scores');
            setLoading(false);
        });
    };

    return (
        <div>
            <h1 className='pt-4 pb-2 text-red-900 text-lg font-semibold'>Bảng điểm trung bình các phòng ban công ty</h1>
            {loading ? (
                <p>Đang tải bảng điểm...</p>
            ) : error ? (
                <p>{error}</p>
            ) : allDepartmentsScore.length > 0 ? (
                <DepartmentGraph departments={allDepartmentsScore} />
            ) : (
                <p>Chưa có dữ liệu.</p>
            )}
            <h3 className='pt-4 text-sm font-bold'>* Chú thích:</h3>    
            <p className='italic text-xs'>Điểm trung bình tính toán bằng cách cộng các điểm trung bình mục tiêu nhân viên nhân với trọng số từng mục tiêu sau đó chia cho tổng số các trọng số.</p>    
        </div>
    );
};

export default OKRScoreBoard;
