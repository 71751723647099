// Import axios
import axios from 'axios';

const fetchCompanyObjectives = () => {
  return axios.get('/api/okr/company/objectives/')
    .then(response => response.data)
    .catch(error => {
      console.error('There has been a problem with your axios operation:', error);
      throw error;
    });
};

const fetchDepartments = () => {
  return axios.get('/api/departments/')
    .then(response => response.data)
    .catch(error => {
      console.error('There has been a problem with your axios operation:', error);
      throw error;
    });
};

const fetchUserObjectives = (userId) => {
  return axios.get(`/api/okr/users/${userId}/objectives/`)
    .then(response => response.data)
    .catch(error => {
      console.error('There has been a problem with your axios operation:', error);
      throw error;
    });
};

const fetchDepartmentObjectives = (deptId) => {
  return axios.get(`/api/okr/departments/${deptId}/objectives/`)
    .then(response => response.data)
    .catch(error => {
      console.error('There has been a problem with your axios operation:', error);
      throw error;
    });
};

const fetchDepartmentEmployeesObjectives = (deptId) => {
  return axios.get(`/api/okr/departments/${deptId}/employees/objectives/`)
    .then(response => response.data)
    .catch(error => {
      console.error('There has been a problem with your axios operation:', error);
      throw error;
    });
};

export const OKRService = {
  fetchCompanyObjectives,
  fetchDepartments,
  fetchUserObjectives,
  fetchDepartmentObjectives,
  fetchDepartmentEmployeesObjectives,
};
