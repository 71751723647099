import React, { createContext, useContext, useState, useEffect } from 'react';

const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        const userString = localStorage.getItem('currentUser');
        if (userString) {
            try {
                const user = JSON.parse(userString);
                console.log("Loaded user:", user);
                setCurrentUser(user);
            } catch (error) {
                console.error('Failed to parse user data:', error);
                localStorage.removeItem('currentUser');
            }
        }
    }, []);
    

    return (
        <UserContext.Provider value={currentUser}>
            {children}
        </UserContext.Provider>
    );
};


export const useCurrentUser = () => useContext(UserContext);
