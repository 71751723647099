import axios from 'axios';
const API_URL = '/api/ticket/';

class TicketService {
  // Create a new ticket with authorization token
  createTicket(ticketData, token) {
    return axios.post(API_URL, ticketData, {
      headers: {
        'Authorization': `Token ${token}`,
        'Content-Type': 'application/json', // Ensure content type is set to application/json
      },
    });
  }

  // Get all tickets with authorization
  getAllTickets() {
    const token = localStorage.getItem('userToken');
    return axios.get(API_URL, {
      headers: {
        'Authorization': `Token ${token}`,
      },
    });
  }

  // Get a single ticket by ID
  getTicketById(ticketId) {
    return axios.get(`${API_URL}${ticketId}/`);
  }

  // Update a specific ticket with authorization token
  updateTicket(ticketId, ticketData, token) {
    return axios.patch(`${API_URL}${ticketId}/`, ticketData, {
      headers: {
        'Authorization': `Token ${token}`,
      },
    });
  }

  // Fetch tickets filtered by start and end date
  getFilteredTickets(startDate, endDate) {
    const token = localStorage.getItem('userToken');
    return axios.get(`${API_URL}filtered/`, {
      headers: {
        'Authorization': `Token ${token}`,
      },
      params: {
        startDate: startDate,
        endDate: endDate,
      }
    });
  }

getStatistics(startDate, endDate) {
  const token = localStorage.getItem('userToken');
  return axios.get('/api/ticket/statistics/', {
      headers: {
          'Authorization': `Token ${token}`,
      },
      params: {
          startDate: startDate,
          endDate: endDate,
      }
  });
}
}

const ticketService = new TicketService();
export default ticketService;


