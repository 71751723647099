function ImageWithCaption({ src, caption }) {
    return (
      <figure style={{ textAlign: 'center' }}>
        <img src={src} alt={caption} style={{ maxWidth: '100%', height: 'auto' }} />
        <figcaption className="text-xs">{caption}</figcaption>
      </figure>
    );
  }
  
  export default ImageWithCaption;
  