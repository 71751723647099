import React, { useState, useEffect } from 'react';
import { Tab, Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/24/outline';
import { addDays, format } from 'date-fns'; // date-fns is a handy library for date operations
import TicketService from './services/TicketService';
import UpdateTicketStatus from './UpdateTicketStatus';
import TicketForm from './TicketForm'; // Import the form component
//import { useCurrentUser } from './UserContext'; // Assuming you're using context to manage current user data

function TicketList() {
    const [tickets, setTickets] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [statistics, setStatistics] = useState([]);
    // const currentUser = useCurrentUser(); // Retrieve the current user from context or state

    useEffect(() => {
        // Optional: Fetch all tickets on component mount or based on other triggers
    }, []);

    const handleDateChange = (dateType, value) => {
        if (dateType === 'start') {
            setStartDate(value);
        } else {
            setEndDate(value);
        }
    };

    const fetchTickets = async () => {
        if (!startDate || !endDate) {
            console.error('Both start date and end date must be selected');
            return;
        }
        try {
            const adjustedEndDate = addDays(new Date(endDate), 1); // Add one day to include the entire endDate
            const response = await TicketService.getFilteredTickets(startDate, format(adjustedEndDate, 'yyyy-MM-dd'));
            console.log(response.data);
            setTickets(response.data);
        } catch (error) {
            console.error('Failed to fetch tickets:', error);
        }
    };
    
    const fetchStatistics = async () => {
        if (!startDate || !endDate) {
            console.error('Both start date and end date must be selected');
            return;
        }
        try {
            const adjustedEndDate = addDays(new Date(endDate), 1); // Adjust the end date here as well
            const response = await TicketService.getStatistics(startDate, format(adjustedEndDate, 'yyyy-MM-dd'));
            console.log("Fetched statistics:", response.data);
            setStatistics(response.data);
        } catch (error) {
            console.error('Failed to fetch statistics:', error);
        }
    };
    
    return (
        <div>
            <p className='font-medium pb-2'>Chọn khoảng thời gian tải ticket</p>
            <div className="date-selector">
                <input type="date" value={startDate} onChange={(e) => handleDateChange('start', e.target.value)} className="p-2 border rounded mr-2" />
                <input type="date" value={endDate} onChange={(e) => handleDateChange('end', e.target.value)} className="p-2 border rounded" />
            </div>
            <Tab.Group>
                <Tab.List className="flex p-1 mt-2 space-x-1 bg-blue-900/20 rounded-xl">
                    <Tab as="div" className={({ selected }) => (selected ? 'bg-blue-500 text-white' : 'bg-white text-black') + ' p-3 rounded-lg'}>Tickets</Tab>
                    <Tab as="div" className={({ selected }) => (selected ? 'bg-blue-500 text-white' : 'bg-white text-black') + ' p-3 rounded-lg'}>Statistics</Tab>
                    <Tab as="div" className={({ selected }) => (selected ? 'bg-blue-500 text-white' : 'bg-white text-black') + ' p-3 rounded-lg'}>List</Tab>
                    <Tab as="div" className={({ selected }) => (selected ? 'bg-blue-500 text-white' : 'bg-white text-black') + ' p-3 rounded-lg'}>Submit Ticket</Tab>
                </Tab.List>
                <Tab.Panels>
                    <Tab.Panel>
                    <button onClick={fetchTickets} className="p-2 mt-8 bg-blue-900 text-white rounded ml-2">Tải danh sách Tickets</button>    
                        {/* Accordion for ticket details */}
                        {tickets.length > 0 ? (
                        tickets.map((ticket) => (
                            <Disclosure as="div" key={ticket.id} className="mt-2">
                                {({ open }) => (
                                    <>
                                        <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                                            <span>{`${ticket.id}: ${ticket.subject} - Created on: ${new Date(ticket.created_at).toLocaleDateString()}`}</span>
                                            <ChevronUpIcon
                                                className={`${open ? 'transform rotate-180' : ''} w-5 h-5 text-blue-500`}
                                            />
                                        </Disclosure.Button>
                                        <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                                            <span className='font-medium'>Chủ đề: </span>{ticket.subject}&nbsp;◊ <span className='font-medium'>Ngày tạo: </span> {new Date(ticket.created_at).toLocaleString()}&nbsp;◊ <span className='font-medium'>Chi tiết công việc: </span> {ticket.description}
                                            {ticket.status_updates.map((update, index) => (
                                                <div key={index} className="mb-2 p-2 border-l-4 border-blue-500">
                                                    <p>{update.updated_by.name} cập nhật lên: {update.new_status} vào lúc {new Date(update.update_time).toLocaleString()}</p>
                                                    <p>Chú thích: {update.comment}</p>
                                                </div>
                                            ))}
                                            {ticket.status !== 'closed' && (
                                                <UpdateTicketStatus ticketId={ticket.id} currentStatus={ticket.status} ticket={ticket} onUpdate={fetchTickets} />
                                            )}
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        ))
                    ) : (
                        <p>No tickets to display. Please select a date range and load tickets.</p>
                    )}
                    </Tab.Panel>

                    <Tab.Panel>
                        <div>
                            <button onClick={fetchStatistics} className="p-2 mt-8 bg-blue-900 text-white rounded">Tải thống kê</button>
                        </div>
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">User</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total Tickets</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Avg Time Open to In-Progress</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Avg Time In-Progress to Resolved</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Avg Time Resolved to Closed</th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {statistics.map((stat, index) => (
                                    <tr key={index}>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{stat.user}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{stat.total_tickets}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{stat.average_open_to_in_progress.toFixed(2)}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{stat.average_in_progress_to_resolved.toFixed(2)}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{stat.average_resolved_to_closed.toFixed(2)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Tab.Panel>
                    <Tab.Panel>
                        {/* Accordion for ticket details */}
                        {tickets.map((ticket) => (
                    <Disclosure as="div" key={ticket.id} className="mt-2">
                        {({ open }) => (
                            <>
                                <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-purple-900 bg-purple-100 rounded-lg hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                                    <span>{ticket.subject} - {ticket.status}</span>
                                    <ChevronUpIcon
                                        className={`${open ? 'transform rotate-180' : ''
                                            } w-5 h-5 text-purple-500`}
                                    />
                                </Disclosure.Button>
                                <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                                {ticket.status_updates.map((update) => (
                                    <div key={update.update_time}>
                                        <p>Status changed to: {update.new_status}</p>
                                        <p>By: {update.updated_by.name}</p>  {/* Assuming updated_by is an object with a name property */}
                                        <p>At: {new Date(update.update_time).toLocaleString()}</p>
                                        <p>Comment: {update.comment} OK</p>{ticket.last_update_comment}
                                    </div>
                                ))}
                                </Disclosure.Panel>
                            </>
                        )}
                    </Disclosure>
                ))}
                    </Tab.Panel>
                    <Tab.Panel>
                        <TicketForm />
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>
        </div>
    );
}

export default TicketList;
