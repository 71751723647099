import React, { useState, useEffect } from 'react';
import { OKRService } from './services/OKRService';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const OKRDepartmentsArchives = () => {
    const [departments, setDepartments] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState(null);
    const [departmentObjectives, setDepartmentObjectives] = useState([]);
    const [employeeObjectives, setEmployeeObjectives] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [showUserCompletion, setShowUserCompletion] = useState(false);
//    const [userCompletions, setUserCompletions] = useState([]);
    const [userCompletions] = useState([]);

    useEffect(() => {
        OKRService.fetchDepartments()
            .then(setDepartments)
            .catch(error => console.error("Fetching departments failed:", error));
    }, []);

    const isOverlapping = (obj) => {
        const objStart = new Date(obj.start_date);
        const objEnd = new Date(obj.end_date);
        return objStart <= endDate && objEnd >= startDate;
    };
    
    useEffect(() => {
    //    console.log("Selected Dates: ", startDate.toISOString(), endDate.toISOString());
        if (selectedDepartment && startDate && endDate) {
            OKRService.fetchDepartmentObjectives(selectedDepartment)
                .then(data => {
    //                console.log("Fetched Department Data: ", data);
                    const filteredObjectives = data.filter(isOverlapping);
    //                console.log("Filtered Department Objectives: ", filteredObjectives);
                    setDepartmentObjectives(filteredObjectives);
                })
                .catch(error => console.error(`Fetching department objectives failed:`, error));
    
            OKRService.fetchDepartmentEmployeesObjectives(selectedDepartment)
                .then(data => {
    //                console.log("Fetched Employee Data: ", data);
                if (data) {    
                        const filteredEmployeeObjectives = data.filter(isOverlapping);
        //                console.log("Filtered Employee Objectives: ", filteredEmployeeObjectives);
                        setEmployeeObjectives(filteredEmployeeObjectives);
                }        
                })
                .catch(error => console.error(`Fetching employee objectives for department ${selectedDepartment} failed:`, error));
        }
    }, [selectedDepartment, startDate, endDate, isOverlapping]);
    

    const getPeriodFromDates = (startDate, endDate) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedStartDate = startDate.toLocaleDateString('en-US', options);
        const formattedEndDate = endDate.toLocaleDateString('en-US', options);
      
        return `${formattedStartDate} - ${formattedEndDate}`;
      };
      

    const displayPeriod = getPeriodFromDates(startDate, endDate);

    const getTextAfterDash = (text) => {
            return text.includes('--') ? text.split('--')[1].trim() : text;
    };

    const ObjectiveDetails = ({ objective }) => (
        <div className="my-4 p-4 border border-gray-200 rounded-lg shadow">
            <h4 className="text-lg text-red-500 font-bold">{getTextAfterDash(objective.title)}</h4>
            <p className="text-sm my-2">{objective.description}</p>
            <p>Start Date: {objective.start_date}</p> {/* Displaying start date */}
            <p>End Date: {objective.end_date}</p>
            <div className="flex items-center justify-left py-2 border-b border-gray-200">
                <div><strong>Objective Owner:</strong> {objective.user_name}</div>
                <div className="text-md font-semibold ml-32 mr-8">Completion: <span className="text-md text-red-900">{objective.completion_percentage?.toFixed(2) || 'N/A'}%</span></div>
            </div>
            {objective.keyresults.map((kr, krIndex) => (
                <div key={kr.id} className="mt-4">
                    <strong>Key Result {krIndex + 1}:</strong> {getTextAfterDash(kr.description)}
                    <p>&#9733; Total Score for Period: {kr.total_score_this_quarter?.toFixed(2)} &#9733; Weight: {kr.weight} &#9733; Target Score: {kr.target_score} &#9733; Achieved: {((kr.total_score_this_quarter / kr.target_score) * 100).toFixed(2) || 'N/A'}%</p>
                    <div className="mt-2">
                        <strong>Weekly Scores:</strong>
                        <div className="mt-1">
                            {kr.historical_scores.map((hs, index) => (
                                <div key={index} className="flex items-center justify-left py-2 border-b border-gray-200">
                                    <span className="text-sm font-medium text-gray-600">Week {index + 1}:</span>
                                    <div className="ml-8 mr-8">
                                        <span className="text-sm text-gray-600">{hs.evaluation_date}</span>
                                    </div>
                                    <div className="ml-5">
                                        <span className="text-sm font-medium text-green-500">{hs.score}</span>
                                    </div>
                                    <div className="ml-16">
                                        <span className="text-sm text-gray-600">{hs.notes}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );

    return (
        <div>
            <h2>Trước hết, hãy chọn 1 kỳ tính điểm ≡</h2>
            <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
                <DatePicker
                    selected={startDate}
                    onChange={date => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat="MMMM d, yyyy"
                />
                <DatePicker
                    selected={endDate}
                    onChange={date => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    dateFormat="MMMM d, yyyy"
                />
            </div>
            {/* Add display components and logic similar to OKRDepartments, but using filtered objectives based on selected dates */}
            <div>
            <h2>Sau đó click để chọn phòng ban cần xem ≡</h2>
            <div className="flex overflow-x-auto space-x-1 py-4">
            {departments.map(dept => (
                <button
                    key={dept.id}
                    onClick={() => setSelectedDepartment(dept.id)}
                    className="bg-red-200 hover:bg-red-300 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                    {dept.name}
                </button>
            ))}
        </div>
        {departmentObjectives.map(obj => (
                        <div key={obj.id}> {/* Use the key here on the parent element */}
                            <div className='font-semibold'>OKR Bộ phận:&nbsp;{obj.department_name}</div>
                        </div>
                    ))}
        <p>Kỳ tính điểm:&nbsp;{displayPeriod} </p>    
                <button
                    onClick={() => setShowUserCompletion(!showUserCompletion)}
                    className="mt-4 bg-red-100 hover:bg-red-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                    {showUserCompletion ? 'Che bảng điểm' : 'Bảng điểm'}
                </button>
        {showUserCompletion && (
                  <div className="mt-4">
                      {userCompletions.map((user, index) => (
                          <div key={index} className="mt-2">
                              <p>{user.userName}: {user.completionPercentage}%</p>
                              <div className="w-full bg-gray-200 rounded-full h-4 dark:bg-gray-700">
                                  <div className="bg-green-600 h-4 rounded-full" style={{ width: `${user.completionPercentage}%` }}></div>
                              </div>
                          </div>
                      ))}
                  </div>
              )}
            {selectedDepartment && (
                <>
                    <h3 className='font-semibold mt-4'>OKR Bộ phận</h3>
                    {departmentObjectives.map(obj => (
                        <div key={obj.id}> {/* Use the key here on the parent element */}
                            <ObjectiveDetails objective={obj} /> 
                        </div>
                    ))}

                    <h3>OKR nhân viên</h3>
                    {employeeObjectives.map(obj => (
                        <div key={obj.id}> {/* And here as well */}
                            <p className='font-semibold text-red-900'>Thực hiện: {obj.user_name}</p>
                            <ObjectiveDetails objective={obj} />
                        </div>
                    ))}
                </>
            )}



        </div>        
        </div>
    );
};

export default OKRDepartmentsArchives;
