import React from 'react';

function Home() {
return (

<div className="flex w-full">
      {/* First Column */}
      <div className="flex flex-col w-full" style={{ flex: "2 2 40%" }}> {/* Adjusted for 2/5 width equivalently */}

        <div className="relative flex flex-col w-full" style={{ flex: "2 2 40%" }}>
        <div className="absolute w-full h-full flex justify-center p-4 z-10 bg-black bg-opacity-0">
          <p className="text-white text-center text-4xl font-tomhum pt-8">Digi và các bạn!</p>
        </div>
        <img src={require('./images/digiers.jpg')} alt="Descriptive Alt Text" className="w-full h-auto" />
      </div>

      </div>

      {/* Second Column */}
      <div className="flex flex-col w-full item-center justify-center" style={{ flex: "1 1 30%" }}> {/* Adjusted for equal remaining width */}
      <div className="flex-1 bg-cyan-500 text-white text-xl flex items-center justify-center">Nhịp sống Digi</div>
        <div className="flex-1 bg-violet-500 text-white text-xl flex items-center justify-center">Ai đang sinh nhật?</div>
        <div className="flex-1 bg-violet-700 text-white text-xl flex items-center justify-center">Bạn đang làm gì?</div>
      </div>

      {/* Third Column */}
      <div className="flex flex-col w-full" style={{ flex: "1 1 30%" }}>
        <div className="flex-1 bg-pink-500 text-white text-xl flex items-center justify-center">Cuối tuần</div>
        <div className="flex-1 bg-orange-500 text-white text-xl flex items-center justify-center">Vui</div>
        <div className="flex-1 bg-yellow-500 text-white text-xl flex items-center justify-center">Gắn kết</div>
      </div>
    </div>
   
  );
}

export default Home;
