import React, { useEffect, useState } from 'react';
import { fetchEmployeeSalesData } from './services/SalesDataService';

const EmployeeSales = () => {
  const [employeeSales, setEmployeeSales] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const token = sessionStorage.getItem('authToken');
    const loadData = async () => {
      const data = await fetchEmployeeSalesData(token);
      if (data) {
        setEmployeeSales(data);
      }
      setIsLoading(false);
    };

    loadData();
  }, []);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
<div>
      <h1>Employee Sales</h1>
      {employeeSales.length > 0 ? (
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Employee Name
              </th>
              <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                Total Sales
              </th>
              <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                Order Count
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {employeeSales.map((emp) => (
              <tr key={emp.employee__user_id}>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {emp.employee__name}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-500">
                  {emp.total_sales.toLocaleString('vi-VN')}₫
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-500">
                  {emp.order_count}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No sales data found.</p>
      )}
    </div>
  );
};

export default EmployeeSales;
