import React, { useEffect, useState } from 'react';
import { fetchSalesData, fetchBranchSalesData } from './services/SalesDataService'; // Adjust the import path
import EmployeeSales from './EmployeeSales';  // Adjust the path as necessary based on your file structure
   
const Tables = () => {
      const [branchSalesData, setBranchSalesData] = useState([]);
      const [salesData, setSalesData] = useState(null);
      const [isLoading, setIsLoading] = useState(true);
    
      useEffect(() => {
        const token = sessionStorage.getItem('authToken'); // Assume the token is stored securely
    
        const loadData = async () => {
          const data = await fetchSalesData(token);
          const branchSales = await fetchBranchSalesData(token);
          console.log(data);
          setBranchSalesData(branchSales);
          setSalesData(data);
          setIsLoading(false);
        };
    
        loadData();
      }, []);
    
      if (isLoading) {
        return <p>Loading sales data...</p>;
      }
    
      return (
        <div>
          {salesData ? (
            <div className="bg-white p-4 shadow rounded-lg">
      <table className="min-w-full table-auto">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Kỳ tính doanh số
            </th>
            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Hiện tại
            </th>
            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Kỳ trước
            </th>
          </tr>
        </thead>
        <tbody className="bg-white">
          <tr>
            <td className="px-4 py-2 whitespace-nowrap text-xl font-bold text-gray-800">
              Tháng
            </td>
            <td className="px-4 py-2 whitespace-nowrap text-xl font-bold text-gray-800">
              {salesData.this_month.toLocaleString('vi-VN',)}
            </td>
            <td className="px-4 py-2 whitespace-nowrap text-xl font-bold text-gray-800">
              {salesData.last_month.toLocaleString('vi-VN',)}
            </td>
          </tr>
          <tr className="bg-gray-50">
            <td className="px-4 py-2 whitespace-nowrap text-xl font-bold text-gray-800">
              Quý
            </td>
            <td className="px-4 py-2 whitespace-nowrap text-xl font-bold text-gray-800">
              {salesData.this_quarter.toLocaleString('vi-VN',)}
            </td>
            <td className="px-4 py-2 whitespace-nowrap text-xl font-bold text-gray-800">
              {salesData.last_quarter.toLocaleString('vi-VN',)}     
            </td>
          </tr>
          <tr>
            <td className="px-4 py-2 whitespace-nowrap text-xl font-bold text-gray-800">
              Năm
            </td>
            <td className="px-4 py-2 whitespace-nowrap text-xl font-bold text-gray-800">
              {salesData.this_year.toLocaleString('vi-VN',)}     
            </td>
            <td className="px-4 py-2 whitespace-nowrap text-xl font-bold text-gray-800">
              {salesData.last_year.toLocaleString('vi-VN',)}     
            </td>
          </tr>
        </tbody>
      </table>
    </div>
          ) : (
            <p>No sales data found.</p>
          )}


{branchSalesData && branchSalesData.length > 0 ? (
        <div className="bg-white p-4 shadow rounded-lg">
          <table className="min-w-full table-auto">
            <thead className="bg-gray-100">
              <tr>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Branch Name
                </th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Total Sales
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {branchSalesData.map((branch) => (
                <tr key={branch.id}>
                  <td className="px-4 py-2 whitespace-nowrap text-xl font-bold text-gray-800">
                    {branch.name}
                  </td>
                  <td className="px-4 py-2 whitespace-nowrap text-xl font-bold text-gray-800">
                    {branch.total_sales.toLocaleString('vi-VN')}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>No sales data found.</p>
      )}


        <EmployeeSales />

        </div>
      );
    };

export default Tables;
