import React from 'react';
import { Link, Outlet } from 'react-router-dom';

function Dashboard() {
    return (
        <div className="bg-gray-100 min-h-screen">
            <div className="max-w-6xl mx-auto py-8">
                <h1 className="font-bold text-2xl text-gray-900 mb-6">Dashboard Digi</h1>
                <nav className="bg-white shadow rounded-lg mb-6">
                    <ul className="flex divide-x divide-gray-300">
                        <li className="flex-1">
                            <Link to="tables" className="block text-center text-blue-700 py-2 px-4 hover:bg-blue-50 transition duration-300 ease-in-out">
                                Số liệu tổng hợp
                            </Link>
                        </li>
                        <li className="flex-1">
                            <Link to="charts" className="block text-center text-blue-700 py-2 px-4 hover:bg-blue-50 transition duration-300 ease-in-out">
                                Biểu đồ
                            </Link>
                        </li>
                        <li className="flex-1">
                            <Link to="marketing" className="block text-center text-blue-700 py-2 px-4 hover:bg-blue-50 transition duration-300 ease-in-out">
                                Marketing
                            </Link>
                        </li>
                    </ul>
                </nav>
                <Outlet /> {/* This renders the current route's component */}
            </div>
        </div>
    );
}

export default Dashboard;
