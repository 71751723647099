import React from 'react';
import OKRImage from './images/mo-hinh-okr.png'; 

function OKRSummary() {
  return (
    <div>
      <h1 className='font-bold font-xl my-4'>Tổng quan về OKR</h1>    
      <p className='font-semibold mb-2'>OKR là gì?</p>
<p>OKR là viết tắt của Objectives and Key Results, tạm dịch là các mục tiêu và kết quả then chốt. Đây là một phương pháp quản trị mục tiêu giúp tổ chức, đội nhóm và cá nhân thiết lập và theo dõi các mục tiêu có thể đo lường được. Cấu trúc của OKR được cấu thành dựa trên 2 yếu tố:

Objective (Mục tiêu): Mục tiêu là đích đến mà tổ chức, đội nhóm hoặc cá nhân muốn đạt được. Mục tiêu cần rõ ràng, cụ thể, có thể đo lường được, có thể đạt được nhưng cũng phải thách thức.

Key Result (Kết quả then chốt): Kết quả then chốt là những chỉ số đo lường sự tiến bộ của tổ chức, đội nhóm hoặc cá nhân trong việc đạt được mục tiêu. Kết quả then chốt cần cụ thể, có thể đo lường được, có thể đạt được và có thời hạn.</p>
<p className='my-2'>OKR có lịch sử lâu đời bắt nguồn từ năm 1954 khi Peter Drucker phát minh ra MBO hay Quản lý theo mục tiêu. Năm 1968, Andrew Grove gia nhập Intel và tiếp tục phát triển MBO thành khuôn khổ OKR như chúng ta biết ngày nay. John Doerr gia nhập Intel vào năm 1974 và học OKR trong thời gian làm việc ở đó. 

Doerr gia nhập Kleiner Perkins Caufield & Byers, một công ty đầu tư mạo hiểm nổi tiếng, trở thành một trong những nhà đầu tư đầu tiên của Google. Doerr đã giới thiệu OKR với những người sáng lập Google, Larry Page và Sergey Brin, những người sau đó đã triển khai khuôn khổ OKR tại Google (hiện vẫn sử dụng nó cho đến ngày nay). Kể từ đó, OKR dần dần trở nên phổ biến và hiện được các tổ chức trên toàn thế giới sử dụng.</p> 

<img className='mx-auto my-4' src={OKRImage} alt="OKR" />
<p>Tại Digi, chúng ta bắt đầu áp dụng OKR từ quý II năm 2024.</p>
    </div>
  );
}

export default OKRSummary;
