import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { format } from 'date-fns';
import ImageComponent from './ImageComponent';

function Articles() {
    const [articles, setArticles] = useState([]);
    const [selectedArticle, setSelectedArticle] = useState(null);

    useEffect(() => {
//        axios.get('http://192.168.4.101:8000/api/news/articles/')
        axios.get('/api/news/articles/')
            .then(response => {
                // First, sort articles by written_date in descending order
                const sortedArticles = response.data.sort((a, b) => new Date(b.written_date) - new Date(a.written_date));
    
                const fetchImages = async () => {
                    return Promise.all(sortedArticles.map(async article => {
                        const articleWithImages = { ...article };
                        // Attempt to fetch and update URL for the main image and additional images
                        if (article.image) {
                            try {
                                const imageResponse = await axios.get(article.image, { responseType: 'blob' });
                                articleWithImages.imageUrl = URL.createObjectURL(imageResponse.data);
                            } catch (error) {
                                console.error('Error fetching main image:', error);
                                articleWithImages.imageUrl = null;
                            }
                        }
    
                        // Attempt to fetch and update URLs for each additional image
                        for (let i = 1; i <= 100; i++) {
                            const imageField = `image${i}`;
                            if (article[imageField]) {
                                try {
                                    const imageResponse = await axios.get(article[imageField], { responseType: 'blob' });
                                    articleWithImages[`${imageField}Url`] = URL.createObjectURL(imageResponse.data);
                                } catch (error) {
                                    console.error(`Error fetching ${imageField}:`, error);
                                    articleWithImages[`${imageField}Url`] = null;
                                }
                            }
                        }
    
                        return articleWithImages;
                    }));
                };
    
                fetchImages().then(updatedArticles => setArticles(updatedArticles));
            })
            .catch(error => console.error('Error fetching articles:', error));
    }, []);
    

    const handleArticleClick = (article) => {
        setSelectedArticle(article);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return format(date, 'HH:mm:ss dd-MM-yyyy');
    };

const renderArticleSummary = (article, index) => (
    <div key={article.id} className="cursor-pointer mb-4" onClick={() => handleArticleClick(article)}>
        <div className="flex items-start">
            {index < 5 && article.image && (
                <div className="flex-shrink-0 flex-grow-0 w-40 h-32 overflow-hidden relative mr-5">
                    <ImageComponent imageSrc={article.image} />
                </div>
            )}
            <div className="flex-grow">
                <h2 className="text-xl font-semibold">{article.title}</h2>
                <p>{article.brief}</p>
            </div>
        </div>
        {/* Visible HR */}
        <hr className="border-t border-gray-300 my-4 w-full"/>
    </div>
);

    const FullArticleView = ({ article }) => {
        return (
            <div>
                <h2 className='font-semibold text-xl text-gray-700'>{article.title}</h2>
                <div className="flex justify-between items-start mb-5">
                    <p>Posted: {formatDate(article.written_date)}</p>
                    {article.author && (
                        <div className="flex flex-col items-center">
                            {article.author.image && (
                                <img 
                                    src={article.author.image} 
                                    alt="Author" 
                                    style={{ width: "100px", height: "100px", borderRadius: "50%", objectFit: "cover" }} 
                                />
                            )}
                            <p style={{ marginTop: '8px' }}>Tác giả:<span><strong> {article.author.name}</strong></span></p>
                        </div>
                    )}
                    <p>{article.introduction}</p>
                </div>            
    
                {[...Array(11)].map((_, i) => {
                    const imageField = `image${i+1}Url`; // Adjusted to match updated article object keys
                    const headingField = `heading${i+1}`;
                    const textField = `text${i+1}`;
    
                    return (
                        <React.Fragment key={`article-detail-${i}`}>
                            {article[headingField] && <h3 className="font-semibold text-xl text-pink-300 mt-4 mb-2">{article[headingField]}</h3>}
                            {article[textField] && article[textField].split('\\n\\n').map((paragraph, pi) => (
                                <p className="mb-2" key={`text-${i}-p-${pi}`}>{paragraph}</p>
                            ))}
                            {article[imageField] && <img src={article[imageField]} alt={`Detail ${i}`} className="mt-6 mb-3 max-w-full mx-auto" />}
                        </React.Fragment>
                    );
                })}
            </div>
        );
    };
    
    

    return (
        <div>
            <h1 className='font-semibold text-lg pb-4'>Trang tin tức Digi</h1>

            {selectedArticle ? (
                <>
                    <button onClick={() => setSelectedArticle(null)} style={{ marginBottom: "20px" }}>≪ Quay lại Mục lục</button>
                    <FullArticleView article={selectedArticle} />
                </>
            ) : (
                articles.map(renderArticleSummary)
            )}
        </div>
    );
}

export default Articles;
