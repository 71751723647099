import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { FiTarget, FiCompass, FiBookOpen } from 'react-icons/fi';
import { fetchGoalsForPlan, fetchDepartmentAndEmployeePlans, fetchDepartments, fetchStrategiesForGoal, fetchTasksForStrategy } from './services/PlanService';

const DepartmentsArchives = () => {
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [departmentPlans, setDepartmentPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [goals, setGoals] = useState([]);
  const [selectedYear, setSelectedYear] = useState(moment().year());
  const [selectedQuarter, setSelectedQuarter] = useState(moment().quarter());
  const statusColorMapping = {
    NS: 'bg-gray-200', // Not started: light gray
    DO: 'bg-green-500', // Done: green
    OV: 'bg-green-700', // Overhead: dark green
    BE: 'bg-yellow-400', // Behind: yellow
    OF: 'bg-gray-600', // OFF: dark gray
    OD: 'bg-red-500', // Overdue: red
  };
  useEffect(() => {
    fetchDepartments().then(setDepartments);
  }, []);

  useEffect(() => {
    if (selectedDepartment && selectedYear && selectedQuarter) {
      const startDate = moment().year(selectedYear).quarter(selectedQuarter).startOf('quarter').format('YYYY-MM-DD');
      const endDate = moment().year(selectedYear).quarter(selectedQuarter).endOf('quarter').format('YYYY-MM-DD');
      fetchDepartmentAndEmployeePlans(selectedDepartment.id, startDate, endDate)
        .then(plans => {
          const inactivePlans = plans.filter(plan => !plan.is_active);
          setDepartmentPlans(inactivePlans);
        })
        .catch(console.error);
    }
  }, [selectedDepartment, selectedYear, selectedQuarter]);

  const handleDepartmentSelect = department => {
    setSelectedDepartment(department);
    setSelectedPlan(null);
    setGoals([]);
  };

  const renderTaskWeeks = (task) => {
    if (!task) {
      console.error('Task data is undefined when trying to render weeks.');
      return null; // Early return to handle undefined task data gracefully
    }
  
    return [...Array(13).keys()].map(week => {
      const statusKey = `week${week + 1}_status`;
      const descriptionKey = `week${week + 1}_description`;
  
      // Ensure that keys are unique and all components are consistently defined
      return (
        <div key={`${task.id}-${week}`} className={`p-2 m-1 flex-1 min-w-[23%] ${statusColorMapping[task[statusKey]]}`}>
          <strong className="font-bold text-xs">Week {week + 1}:</strong>
          <span className="text-xs font-light text-gray-600">&nbsp;{task[descriptionKey] || 'No description'}</span>
        </div>
      );
    });
  };
  
  const handlePlanSelect = async plan => {
    setSelectedPlan(plan);
    try {
      const fetchedGoals = await fetchGoalsForPlan(plan.id);
      const goalsWithDetails = await Promise.all(fetchedGoals.map(async goal => {
        const strategies = await fetchStrategiesForGoal(goal.id);
        const strategiesWithTasks = await Promise.all(strategies.map(async strategy => {
          const tasks = await fetchTasksForStrategy(strategy.id);
          if (!tasks || tasks.length === 0) {
            console.log(`No tasks found for strategy ${strategy.id}`);
            return { ...strategy, tasks: [] };
          }
          return { ...strategy, tasks: tasks };
        }));
        return { ...goal, strategies: strategiesWithTasks };
      }));
      setGoals(goalsWithDetails);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  };
  
  const currentYear = moment().year();
  const startYear = 2023;
  const yearOptions = Array.from({ length: currentYear - startYear + 1 }, (_, i) => startYear + i);

  const getTextAfterDash = (text) => {
    return text.includes('--') ? text.split('--')[1].trim() : text;
  };

  return (
    <div>
      <h2 className='font-bold mt-4 mb-2'>Các kế hoạch cũ</h2>
      <div className='flex gap-4 mb-4'>
      <select className='border p-2 rounded appearance-none bg-white pr-8 relative' value={selectedQuarter} onChange={e => setSelectedQuarter(e.target.value)}>
        {[1, 2, 3, 4].map(quarter => <option key={quarter} value={quarter}>Quý {quarter}</option>)}
      </select>
      <select className='border p-2 rounded appearance-none bg-white pr-8 relative' value={selectedYear} onChange={e => setSelectedYear(e.target.value)}>
        {yearOptions.map(year => <option key={year} value={year}>{year}</option>)}
      </select>
    </div>
      {departments.map(department => (
        <button key={department.id} className='text-yellow-900 hover:font-bold cursor-pointer' onClick={() => handleDepartmentSelect(department)}>
          &nbsp;* {department.name} 
        </button>
      ))}
      {selectedDepartment && (
        <div>
          <h3 className='font-medium text-lg mt-4 mb-2'>Plan Details</h3>
          {departmentPlans.map(plan => (
            <div key={plan.id} className="flex items-center cursor-pointer" onClick={() => handlePlanSelect(plan)}>
              <FiBookOpen className="mr-2" />
              <span>{getTextAfterDash(plan.title)} - Score: {plan.calculated_score}</span>
            </div>
          ))}
        </div>
      )}
        {selectedPlan && goals.map((goal, index) => (
        <div key={goal.id}>
            <div className="flex items-center border-b pt-2 mt-4 bg-slate-50">
            <FiTarget className="mr-2" />
            <span className='font-semibold'>Goal {index + 1} ({goal.category_name}):</span> {getTextAfterDash(goal.name)}
            </div>
            {goal.strategies.map((strategy, strategyIndex) => (
            <div key={strategy.id}>
                <div className="flex items-center mt-4 mb-2">
                <FiCompass className="mr-2" />
                <h5><span className='font-semibold'>Strategy {strategyIndex + 1}:</span> {getTextAfterDash(strategy.name)}</h5>
                </div>
                {strategy.tasks && strategy.tasks.length > 0 ? (
                    strategy.tasks.map(task => (
                        <div key={task.id} className="flex flex-wrap">
                        {renderTaskWeeks(task)}
                        </div>
                    ))
                    ) : (
                    <div>No tasks available</div>
                )}

            </div>
            ))}
        </div>
        ))}

    </div>
  );
};

export default DepartmentsArchives;
