import React from 'react';
import PBCImage from './images/pbc.jpg'; 
import ImageWithCaption from './services/ImageWithCaption'; 


function PlanIntroduction() {
  return (
    <div>
      <h1 className='font-medium text-2xl my-4'>Kế hoạch 90 ngày: đôi lời giới thiệu</h1>
      <p className='mb-4'>Kế hoạch hành động 90 ngày được xem là kế hoạch quan trọng đối với việc thực thi mục tiêu 1 năm, 3-5 năm hay dài hạn hơn. Thử tưởng tượng, nếu kế hoạch quý không hoàn thành, làm thế nào doanh nghiệp hoàn thành được kế hoạch năm hay xa hơn là thực hiện được tầm nhìn? </p>
      <ImageWithCaption className='mx-auto my-4' src={PBCImage} alt="PBC" caption={'Hình: một buổi học ở PBC'}/>
      <p className='my-4'>Những bất cập về lập kế hoạch mà chúng ta đang gặp phải:</p>
      <ul className="list-disc ml-8">  
            <li>Chưa có thói quen lập Kế hoạch theo quý</li>
              <li>Thiết lập mục tiêu/kế hoạch năm xong thì cất vào tủ và không thực hiện theo kế hoạch</li>
              <li>Triển khai từ mục tiêu ra kế hoạch không được, đặc biệt là từ năm xuống quý, xuống tháng và xuống tuần</li>
              <li>Đặt quá nhiều mục tiêu trong thời gian ngắn nhưng trong dài hạn thì lại không tận dụng, làm không nổi nên vỡ kế hoạch</li>
              <li>Không dự liệu được thời gian của các công việc liên tiếp nhau giúp hoàn thành mục tiêu, nên thực tế khác xa kế hoạch</li>
              <li>Không có kế hoạch để WORK ON (những việc phát triển doanh nghiệp) mà thường chỉ quan tâm đến WORK IN (những việc hàng ngày)</li>
        </ul> 
      <p className='my-4'>Vì vậy tăng cường hiểu biết về lập kế hoạch là việc làm vô cùng cần thiết!</p>
    </div>
  );
}

export default PlanIntroduction;
