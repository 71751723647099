import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import {
  fetchCompanyPlan,
  fetchDepartments,
  fetchDepartmentAndEmployeePlans,
} from './services/PlanService';

const PlanEvaluation = () => {
  const [activeTab, setActiveTab] = useState('company');
  const [companyPlan, setCompanyPlan] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [departmentPlansDetails, setDepartmentPlansDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

    const getCurrentQuarterDates = () => {
      //const now = moment();
      //const quarter = Math.floor(now.month() / 3);
      const firstDate = moment().startOf('quarter').format('YYYY-MM-DD');
      const lastDate = moment(firstDate).endOf('quarter').format('YYYY-MM-DD');
      return { startDate: firstDate, endDate: lastDate };
    };
  
    const { startDate, endDate } = getCurrentQuarterDates();

    useEffect(() => {
      const fetchInitialData = async () => {
        setIsLoading(true);
        const plan = await fetchCompanyPlan(startDate, endDate);
        const deps = await fetchDepartments();
        setCompanyPlan(plan);
        setDepartments(deps);
    
        // If the "All" tab is active, fetch all department plans as well
        if (activeTab === 'all') {
          for (const department of deps) {
            const departmentPlans = await fetchDepartmentAndEmployeePlans(department.id, startDate, endDate);
            department.plans = departmentPlans;  // Attach plans to each department
          }
          setDepartments([...deps]);  // Trigger re-render
        }
        setIsLoading(false);
      };
      fetchInitialData();
    }, [startDate, endDate, activeTab]);  // Include activeTab as a dependency
    
  

    const handleDepartmentClick = async (departmentId) => {
      setIsLoading(true);
      // Fetch the department plans directly without fetching additional details for each plan
      const departmentPlans = await fetchDepartmentAndEmployeePlans(departmentId, startDate, endDate);
      setDepartmentPlansDetails(departmentPlans || []);
      setIsLoading(false);
    };

  return (
<div className="pt-4">
  <div className="flex mb-4">
    <button
      onClick={() => setActiveTab('company')}
      className={`py-2 pl-4 pr-8 ${activeTab === 'company' ? 'bg-red-300 text-white' : 'bg-gray-200'}`}
      style={{
        clipPath: 'polygon(0% 0%, 80% 0%, 100% 100%, 0% 100%)',
        transition: 'all 0.3s',
        willChange: 'transform',
        display: 'inline-block',
      }}
    >
    điểm Công ty
  </button>
  <button
    onClick={() => setActiveTab('departments')}
    className={`py-2 pl-4 pr-8 ${activeTab === 'departments' ? 'bg-red-300 text-white' : 'bg-gray-200'}`}
    style={{
      clipPath: 'polygon(0% 0%, 80% 0%, 100% 100%, 0% 100%)',
      transition: 'all 0.3s',
      willChange: 'transform',
      display: 'inline-block',
    }}
  >
    điểm Phòng ban
  </button>
  <button
  onClick={() => setActiveTab('all')}
  className={`py-2 pl-4 pr-8 ${activeTab === 'all' ? 'bg-red-300 text-white' : 'bg-gray-200'}`}
  style={{
    clipPath: 'polygon(0% 0%, 80% 0%, 100% 100%, 0% 100%)',
    transition: 'all 0.3s',
    willChange: 'transform',
    display: 'inline-block',
  }}
>
  Bảng tổng sắp
</button>

</div>

      {activeTab === 'company' && companyPlan && (
        <div className="bg-gray-100 p-4 rounded-lg grid grid-cols-5 gap-4">
          <h3 className="font-semibold col-span-5 text-lg">{companyPlan.title}</h3>
          <p className="col-span-5">{companyPlan.description}</p>
        </div>
      )}

      {activeTab === 'departments' && (
        <div>
          <div className="flex overflow-auto space-x-1 mb-4">
            {departments.map((dept) => (
              <button
                key={dept.id}
                onClick={() => handleDepartmentClick(dept.id)}
                className="bg-red-100 hover:bg-red-200 text-gray-800 font-semibold py-2 px-4 rounded inline-flex items-center"
              >
                {dept.name}
              </button>
            ))}
          </div>
          {isLoading ? (
            <p>Loading department plans...</p>
          ) : departmentPlansDetails.length > 0 ? (
            <div className="flex flex-col">
              {departmentPlansDetails.map((plan, index) => (
                <div key={index} className="bg-gray-100 p-4 rounded-lg grid grid-cols-5 gap-4 mb-4">
                  <p className="font-semibold">{plan.title}</p>
                  <p className='text-sm font-light'>{plan.description}</p>
                  <p>Score: {plan.calculated_score || 'N/A'}</p>
                  <p>Target: {plan.target_score || 'N/A'}</p>
                </div>
              ))}
            </div>
          ) : (
            <div>
            <p>Hãy bấm vào tên của bộ phận để xem điểm.</p>
            <p>Nếu bạn không thấy gì có nghĩa là kế hoạch chưa có.</p>
            </div>
          )}
        </div>
      )}
{activeTab === 'all' && (
  <div>
    {/* Heading Row */}
    <div className="bg-gray-200 p-4 rounded-t-lg grid grid-cols-7 gap-4 font-semibold">
      <p className="col-span-1">Name</p>
      <p className="col-span-2">Description</p>
      <p className="col-span-1">Score</p>
      <p className="col-span-1">Target</p>
      <p className="col-span-2">Progress</p>
    </div>
    
    {/* Company Plan */}
    {companyPlan && (
  <div className="bg-gray-100 p-4 rounded-lg grid grid-cols-7 gap-4 mt-2">
    <h3 className="col-span-1 font-semibold text-lg text-red-900">{companyPlan.title}</h3>
    <p className="col-span-2 text-red-700">{companyPlan.description}</p>
    <p className="col-span-1">{companyPlan.calculated_score || 'N/A'}</p>
    <p className="col-span-1">{companyPlan.target_score || 'N/A'}</p>
    <div className="col-span-2 flex items-center">
      <div className="w-full bg-gray-300 rounded-full h-2.5">
        <div 
          className={`h-2.5 rounded-full ${
            isNaN(companyPlan.calculated_score) || isNaN(companyPlan.target_score) || companyPlan.target_score === 0
              ? 'bg-gray-500' // Gray for undefined or zero target score
              : (companyPlan.calculated_score / companyPlan.target_score) * 100 >= 75
              ? 'bg-green-500' // Green for 75% and above
              : (companyPlan.calculated_score / companyPlan.target_score) * 100 >= 50
              ? 'bg-yellow-500' // Yellow for 50% to 74%
              : 'bg-red-500' // Red for below 50%
          }`}
          style={{ 
            width: `${isNaN(companyPlan.calculated_score) || isNaN(companyPlan.target_score) || companyPlan.target_score === 0 
              ? 0 
              : Math.min(100, (companyPlan.calculated_score / companyPlan.target_score) * 100)
            }%` 
          }}
        ></div>
      </div>
    </div>
  </div>
)}

<p className='font-bold pt-6'>Kế hoạch các phòng ban</p>
    
    {/* Departments and Their Plans */}
    {departments.map((dept, index) => (
      <div key={index}>
        <h3 className="text-lg font-semibold my-2">{dept.name}</h3>
        {dept.plans && dept.plans.length > 0 ? (
          dept.plans.map((plan, planIndex) => (
            <div key={planIndex} className="bg-gray-100 p-4 rounded-lg grid grid-cols-7 gap-4 mb-4">
              <p className="col-span-1 text-base font-semibold">{plan.owner.name}</p>
              <p className="col-span-2 text-sm">{plan.description}</p>
              <p className="col-span-1">{plan.calculated_score || 'N/A'}</p>
              <p className="col-span-1">{plan.target_score || 'N/A'}</p>
              <div className="col-span-2 flex items-center">
                <div className="w-full bg-gray-300 rounded-full h-2.5">
                  <div 
                    className={`h-2.5 rounded-full ${
                      isNaN(plan.calculated_score) || isNaN(plan.target_score) || plan.target_score === 0
                        ? 'bg-gray-500' // Gray for undefined or zero target score
                        : (plan.calculated_score / plan.target_score) * 100 >= 75
                        ? 'bg-green-500' // Green for 75% and above
                        : (plan.calculated_score / plan.target_score) * 100 >= 50
                        ? 'bg-yellow-500' // Yellow for 50% to 74%
                        : 'bg-red-500' // Red for below 50%
                    }`}
                    style={{ 
                      width: `${
                        isNaN(plan.calculated_score) || isNaN(plan.target_score) || plan.target_score === 0 
                        ? 0 
                        : Math.min(100, (plan.calculated_score / plan.target_score) * 100)
                      }%` 
                    }}
                  ></div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No plans available for this department.</p>
        )}
      </div>
    ))}

  </div>
)}


    </div>
  );
};

export default PlanEvaluation;
