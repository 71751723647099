// src/utils/axiosSetup.js
import axios from 'axios';

const getToken = () => localStorage.getItem('userToken');

axios.interceptors.request.use((config) => {
    const token = getToken();
    if (token) {
        config.headers['Authorization'] = `Token ${token}`;
        config.headers['Content-Type'] = 'application/json';
    }
    // Debugging: log the Axios request configuration
    // console.log('Axios Request Config:', config);
    return config;
}, error => {
    console.log('Axios Request Error:', error);
    return Promise.reject(error);
});

export const setupAxiosInterceptors = () => {}; // Export an empty function if needed for import side-effects
