import React from 'react';
import SalesComparisonChart from './SalesComparisonChart';

const Marketing = () => {
    const authToken = sessionStorage.getItem('authToken');  

    return (
        <div className="p-4 bg-white rounded shadow">
            <p>Tình hình kinh doanh qua các con số</p>  
            <SalesComparisonChart token={authToken} />
        </div>
    );
}

export default Marketing;
