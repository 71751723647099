import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom'; // Make sure to import Link

function LoginForm({ setIsLoggedIn }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoggedIn, setIsLoggedInLocal] = useState(false);  // Local state to manage login status within the component

    const handleLogin = (e) => {
        e.preventDefault();

        axios.post('/api/login/', { username, password })
            .then(response => {
                const { token, user_id, department } = response.data;
                const userData = {
                    token,
                    user_id,
                    department: department || { code: 'SV', name: 'Customer Service' }
                };
                localStorage.setItem('userToken', token);
                localStorage.setItem('currentUser', JSON.stringify(userData));
                setIsLoggedIn(true); // This will update the App's state
                setIsLoggedInLocal(true); // This updates the local component state
                localStorage.setItem('isLoggedIn', true);
                console.log("Login successful!", userData);
            })
            .catch(error => {
                console.error("Login error:", error);
                alert("Failed to login, please check your credentials.");
            });
    };

    if (isLoggedIn) { // Use local state for conditional rendering
        return (
            <div>
                <p>Đăng nhập thành công. <Link to="/" className="text-blue-500 hover:text-blue-800">Nhấn menu phía trên hoặc nhấn vào đây để về trang chính</Link>.</p>
            </div>
        );
    } else {
        return (
            <form onSubmit={handleLogin}>
                <label>
                    Username:
                    <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
                </label>
                <label>
                    Password:
                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                </label>
                <button type="submit">Login</button>
            </form>
        );
    }
}

export default LoginForm;
