import React from 'react';
import { Outlet, Link } from 'react-router-dom';
import moment from 'moment-timezone';
import { PiAirplaneTiltBold } from "react-icons/pi";
import { FcPlanner } from "react-icons/fc";

const getCurrentQuarterDates = () => {
  const now = moment();
  const quarter = Math.ceil((now.month()+1) / 3);
  const firstDate = moment().startOf('quarter').format('DD/MM/YYYY');
  const lastDate = moment().endOf('quarter').format('DD/MM/YYYY');
  const currentQuarter = `Q${quarter}-${now.year()} (${firstDate} - ${lastDate})`;
  const today = moment().format('DD/MM/YYYY');
  const daysToEnd = moment().endOf('quarter').diff(moment(), 'days');

  return { currentQuarter, today, daysToEnd };
};

const { currentQuarter, today, daysToEnd } = getCurrentQuarterDates();

function Plans() {
  return (
    <div>
      <div className=''>
        <h1 className='font-bold font-xl flex items-center'>KẾ HOẠCH 90 NGÀY &nbsp; <FcPlanner /> &nbsp;&nbsp;CÔNG TY &nbsp;&nbsp; <PiAirplaneTiltBold />&nbsp;&nbsp; PHÒNG BAN &nbsp;<PiAirplaneTiltBold /> &nbsp;&nbsp;CÁ NHÂN</h1>
      </div>
      <div className="flex justify-center items-center">
        <div className="info-box my-8 p-4 rounded-md border border-dashed border-4 border-red-900 inline-block text-center bg-white shadow-lg">
          <h3 className="text-xl font-semibold text-gray-800 mb-2">Kế hoạch hiện tại</h3>
          <div className="text-left">
            <div className="text-md text-gray-800 mb-2">
              Giai đoạn: <span className="font-bold text-xl text-red-700">{currentQuarter}</span>
            </div>
            <div className="text-md text-gray-800 mb-2">
              Ngày hiện tại: <span className="font-bold text-xl text-red-600 bg-gray-300 px-2 py-1 rounded">{today}</span>
            </div>
            <div className="text-md text-gray-800">
              Thời gian còn lại: <span className="font-bold text-xl text-red-500 bg-gray-300 px-2 py-1 rounded">{daysToEnd} ngày</span>
            </div>
          </div>
        </div>
      </div>




      <nav>
      <Link to="introduction" className='underline font-medium font-lg hover:font-bold'>Giới thiệu</Link> &#9734; 
      <Link to="summary" className='underline font-medium font-sm hover:font-bold'>&nbsp; Bảng điểm</Link> &#9734; 
        <Link to="company" className='underline font-medium font-sm hover:font-bold'>&nbsp; Kế hoạch công ty</Link> &#9734; 
        <Link to="departments" className='underline font-medium font-sm hover:font-bold'>&nbsp; Kế hoạch phòng ban</Link> &#9734; 
        <Link to="departmentsarchives" className='underline font-medium font-sm hover:font-bold'>&nbsp; Kho lưu trữ</Link>
      </nav>


      <Outlet /> {/* This renders the current route's component */}
      
    </div>
  );
}

export default Plans;
